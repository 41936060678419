import React, { useState } from "react";
import "./App.css"; // Import the CSS file for styling
import frontimg from "./assets/frontimg.gif"; // Import the default image]

const App = () => {
  const [imagePreview, setImagePreview] = useState(frontimg); // Stores the image preview URL
  const [carData, setCarData] = useState(null); // Stores the car data
  const [errorMessage, setErrorMessage] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false); // Tracks if the image is being analyzed

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setErrorMessage("Please upload an image.");
      return;
    }

    setImagePreview(URL.createObjectURL(file)); // Generate preview URL
    setErrorMessage("");
    setCarData(null);
    setIsAnalyzing(true); // Set analyzing state to true

    const formData = new FormData();
    formData.append("imageToAnalyze", file);

    try {
      const response = await fetch("/api/ImageCarInformation", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();

      if (result.status === "success") {
        setCarData(result["car data"]);
        setErrorMessage("");
      } else {
        setCarData(null);
        setErrorMessage(result["error message"] || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      setCarData(null);
      setErrorMessage("An error occurred and the image could not be analyzed");
    } finally {
      setIsAnalyzing(false); // Reset analyzing state
    }
  };
  
  return (
    <div className="app-container">
      <h1>Figure out what car you've spotted using AI</h1>
      {imagePreview && (
        <div className="image-preview-container">
          <img
            src={imagePreview}
            alt="Uploaded preview"
            className="image-preview"
          />
        </div>
      )}

      <div className="file-input-container">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          id="file-upload"
          className="hidden-file-input"
        />
        <label htmlFor="file-upload" className="custom-file-input">
          Choose an Image
        </label>
      </div>

      <div className="results-container">
        {isAnalyzing && (
          <div className="analyzing-container">
            <div className="loading-spinner"></div>
            <p className="analyzing-text">Analyzing image</p>
          </div>
        )}
        {errorMessage && <p className="error-text">{errorMessage}</p>}
        {carData && (
          <div className="car-data">
            <h2>Your car is a <strong>{carData.Make} {carData.Model}</strong></h2>
            <p><strong>Fun fact:</strong> {carData.Fact}</p>
          </div>
        )}
      </div>

      <footer className="footer">
        The tool is far from perfect, meaning it will occasionally make mistakes. Especially the fact may be a complete hallucination. If you find a persistent bug, please contact me. Made by Stijn Gjaltema.
      </footer>
    </div>
  );
};

export default App;
